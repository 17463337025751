<template>
  <router-view />
</template>

<style lang="scss">
@import "@/variables.scss";


// Include de componenten die je nodig hebt
// Zie node_modules/bootstrap/scss/bootstrap.scss voor de volledige lijst
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/forms";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/list-group";
// @import "bootstrap/scss/tooltip";
@import "bootstrap/scss/utilities/api";

@import "../platformfrontend/scss/platformstyle-bootstrap5-styles.scss";
@import "../platformfrontend/scss/platformstyle-glyphicon.scss";
</style>
