export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "header": {
          "sourceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht ID"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afzender"])},
          "to": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ontvanger"]), _normalize(["Ontvangers"])])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwerp"])}
        }
      }
    }
  })
}