import script from "./Home.vue?vue&type=script&lang=ts&setup=true"
export * from "./Home.vue?vue&type=script&lang=ts&setup=true"

import "./Home.vue?vue&type=style&index=0&id=ac161c0c&lang=scss"
/* custom blocks */
import block0 from "./Home.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__