export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "messageList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzonden berichten"])},
        "status": {
          "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten worden afgevangen"])},
          "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezig met verbinden..."])}
        }
      }
    }
  })
}