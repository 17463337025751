import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

// Voor nu is alles embedded in LEF, als we ook dingen willen gaan embedden in bv MAX dan hier de cookie namen toevoegen voor fallback
const JWTKeys = ['JWTLEF'];
const DefaultLocale = 'nl-NL';


export class PlatformJWT
{
    public static JWT: string | null = null;
    public static Decoded: any | null = null;

    public static init(): void
    {
        let cookie;

        for (const jwtKey of JWTKeys) 
        {
            cookie = Cookies.get(jwtKey);
            if (!!cookie)
                break;                
        }

        if (!cookie)
            return;

        this.JWT = cookie;
        this.Decoded = jwt_decode(cookie);
    }


    public static isAdmin(): boolean
    {
        if (this.Decoded === null)
            return false;

        return this.Decoded.adm;
    }


    public static language(): string
    {
        if (this.Decoded === null || !this.Decoded.lng)
            return DefaultLocale;

        // In de JWT wordt de taal opgeslagen als nlNL. Dat blijkt achteraf niet de meest gelukkige keuze, omdat
        // de Culture in .NET 'nl-NL' gebruikt, de ISO standaard zeg maar. Misschien dat we dit ooit willen veranderen,
        // tot die tijd deze helper
        var taalJWTFormat = /^([a-zA-Z]{2})([a-zA-Z]{2})$/;
        var match = taalJWTFormat.exec(this.Decoded.lng);

        if (match === null)
            return DefaultLocale;

        return match[1] + '-' + match[2];
    }
    

    // Toegevoegd voor PoC beheer, mogelijk niet in alle gevallen toereikend
    public static isValid(): boolean
    {
        if(this.Decoded === null)
            return false;
        
        const extraAllowedOvertime = 300 * 60;
        const current_time = Math.floor(Date.now() / 1000);

        return (this.Decoded.exp + extraAllowedOvertime) >= current_time && !!this.Decoded.aut; 
    }
}


PlatformJWT.init();