import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './App.vue'
import router from './router'
import { PlatformJWT } from '../platformfrontend/ts/platformJWT';


import { faEnvelope, faCommentSms, faBolt } from '@fortawesome/free-solid-svg-icons';

library.add(faEnvelope, faCommentSms, faBolt);


const i18n = createI18n({
  locale: PlatformJWT.language(),
  fallbackLocale: 'nl',
  missingWarn: false,
  fallbackWarn: false,
  legacy: false
});

createApp(App)
  .use(router)
  .use(i18n)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');