export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "header": {
          "fromName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afzender naam"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])}
        },
        "characterCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " karakter"]), _normalize([_interpolate(_named("count")), " karakters"])])}
      }
    }
  })
}